exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-debitservice-js": () => import("./../../../src/pages/debitservice.js" /* webpackChunkName: "component---src-pages-debitservice-js" */),
  "component---src-pages-directdebit-js": () => import("./../../../src/pages/directdebit.js" /* webpackChunkName: "component---src-pages-directdebit-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mailchimp-confirmation-js": () => import("./../../../src/pages/mailchimp-confirmation.js" /* webpackChunkName: "component---src-pages-mailchimp-confirmation-js" */),
  "component---src-pages-preview-journal-index-js": () => import("./../../../src/pages/preview/journal/index.js" /* webpackChunkName: "component---src-pages-preview-journal-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */)
}

